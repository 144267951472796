/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import url('https: //fonts.googleapis.com/css2?family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet');



body, h1, h2, h3, p, ul, li {
  
  padding: 0;
}

.search-box {
  outline-width: 0;
  border: none;
  font-size: 1.25rem;
  margin-left: 10px;
  
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.search-box {
  outline-width: 0;
  border: none;
  margin-left: 10px;
  font-size: 1.25rem;
  color: #333;
}

.sb-search {
  background-color: white;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.sb-header {
  background-color: white;
  border-radius: 20px;
  padding: 10px 5px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}


.sb-conversations {
  background-color: white;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 10px;
  flex: 1;
}

.conversation-container {
  display: grid;
  grid-template-columns: 32px auto auto;
  grid-template-rows: auto auto;
  column-gap: 10px;
  padding: 5px;
  margin: 5px;
  border-radius: 20px;
  background-color:#9cc4dc20;
}
.text-input-area {
  background-color: white;
  padding: 10px 10px;
  margin: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}
.con-title {

  grid-area: 1/2/2/4;

  display: flex;
  color:#333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  
}

.messageBox {

  /* display: grid;
  grid-template-columns: 32px auto auto;
  grid-template-rows: auto auto; */
  column-gap: 10px;
  padding: 5px;
  margin: 5px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.054);

}

.messages-container {
  overflow: scroll;
 
}

.other-message-container {
  display: flex;
  color: black;
  background-color: black;
  width: 0px;
  height:fit-content;
}

.self-message-container {
  display: flex;
  justify-content: flex-end;
}

.con-time-stamp {
  justify-self: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.75rem;
  color:#333;
}

.con-last-message {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.75rem;
  color:#2c3e50;
}
.con-icon {
  grid-area: 1/1/3/2;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecf0f1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
  font-weight: bolder;
  color:#ffffff;
  height: 32px;
  width: 32px;
  padding: 2px;

  border-radius: 50%;
  justify-self: center;
  align-self: center;
}



.sidebar-container{
  /* background-color: rgb(209, 255, 221); */
  flex: 0.3;
  display: flex;
  flex-direction: column;
}




.chatArea-header {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color:white;
  padding: 10px 10px;
  margin: 10px;
  border-radius: 20px;
}

.messages-container {
  background-color:white;
  flex: 1;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
}

.chatArea-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0.7;
}

*
{
margin: 0;
padding: 0;
}

.chat {
  /* background-color: #bdc3c7;
  min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  background-color:#f0f0f0;
  height: 90vh;
  width: 90vw;
  display: flex;
  border-radius: 20px;
  margin-top: 75px;
  margin-bottom: 75px;
  margin-left: 75px;
  opacity: 95%;
}

h2 {
  text-decoration: none;
}

.App {
  background-color: #c9d8e2;
  min-height: 100vh;
  
}

.email {
  font-family: Arial, Helvetica, sans-serif;
  text-size-adjust: 20px;
  
  color: #ffffff;
  padding: 20px;
}

h1 {
  color:#ffffff;
  text-decoration: none;
}



.forms {
  padding: 75px;
}

h1:hover {
  color: #dc3545;
  transition: 300ms;
}

.pages {
  color: #2c3e50;
  /* padding: 50px; */
  
}
.twoButtons {
  padding: 20px;
  align-self: flex-end;
}

.material-symbols-outlined {
  background-color: #e5eaec;
  border-radius: 50%;
  padding: 5px;
  /* background-color: #db3434; */
  /* color: rgb(0, 0, 0);
  padding: 15px;
  border: none;
  border-radius: 5px; */
  cursor: pointer;
  /* font-size: 2em; */

  
}
.material-symbols-outlined:hover {
  background-color: #ffc6c6;
  transition: 300ms;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  background-color: white;
  color: #333;
  
}

.container {
  max-width: 1200px;
  margin:0;
  padding-left: 20px;
  padding-bottom: 0px;
}

header a {
  
  color: #ecf0f1;
  text-decoration: none;
}

/* Header styles */
header {
  background-color: #2c3e50a1;
  padding: 20px 0;
 

position: relative;

  width: 100%;
  z-index: 3; /* Ensure the header is above both the video and content */
  
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
 
  
}

nav a {
  text-decoration: none;
  color: #ecf0f1;
  margin-right: 20px;
  font-size: 1.2em;
  transition: color 0.3s ease;
  
}

nav a:hover {
  color: #3498db;
}

/* Main content styles */
main {
  padding: 20px;
}

.home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
}

.workout-details {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  padding: 20px;
  margin: 30px;
}

.workout-details:hover {
  transform: translateY(-5px);
}

.workout-details h4 {
  color: #3498db;
}

/* Create form styles */

.home {
  /* display: flex; */
}

.workout-form-div {
  /* flex: 0.5; */
  /* min-width: fit-content; */
}

.workouts {
  opacity: 0.7;
  /* display: flex; */
  
/* flex: 0.5; */
}

.workout-form-actions {
  margin: 50px;
  
  color: #000000;
  background-color: #ffffff00;
  border-radius: 20px;
  min-width:fit-content;
}

.workout-form-actions h3 {
  color: white;
}

.delete-all-workouts-button {
  
  margin: 10px;
  margin-left: 25px;
  /* color:#2980b9; */
  background-color: #00000000;
 
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.workout-form-demo-button {
  background-color: #db3434;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.3s ease;
}

.workout-form-demo-button:hover {
  background-color: #000000;
}

.workout-form-div-title {
  color: #e5eaec;
}
.create {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.create h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ffffff89;
}

.create label {
  display: block;
  margin-bottom: 10px;
  color: #ffffffcc;
}

.create input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

.create button {
  /* background-color: #3498db; */
  /* color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease; */
}

.create button:hover {
  /* background-color: #2980b9; */
}

/* Login and Signup form styles */
.login,
.signup {
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px;
}

.login h3,
.signup h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #dc3546;
}

.login label,
.signup label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.login input,
.signup input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 0px solid #00000050;
  
  border-radius: 5px;
  background-color: #0000000c;
}

.login input:focus,
.signup input:focus {
  border: 0.1px solid #00000050; /* Change the border width and color */
}


.login button:hover,
.signup button:hover {
  background-color: #dc3546ac;
}

/* Responsive styles */
@media (max-width: 768px) {
  .home {
    grid-template-columns: 1fr;
  }

  .create,
  .login,
  .signup {
    width: 100%;
  }

  nav {
    flex-direction: column;
  }

  nav a {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Basic Button Styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #3498db; /* Border color */
  color: #3498db; /* Text color */
  background-color: #ffffff; /* Background color */
  border-radius: 5px; /* Rounded corners */
  align-self: first baseline;
  /* Transition for smooth hover effect */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Hover Effect */
.button:hover {
  background-color: #3498db; /* Background color on hover */
  color: #ffffff; /* Text color on hover */
  border-color: #ffffff; /* Border color on hover */
}

/* styles.css or your preferred CSS file */
.logout {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
  

}

.logout:hover {
  background-color: #2980b9;
}

input.error {
  border: 1px solid rgb(189, 16, 16);
  
}















.navH {
  background-color: #333;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.nav-buttons {
  display: flex;
  flex-direction: row; /* Display buttons vertically */
  align-items: center; /* Center items horizontally */
  max-width:fit-content;
  color:#333;
  width: 100%;
}

.nav-button {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;

  padding: 10px 20px;
  border: none;
  background-color:#333;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.nav-button:hover {
  background-color: #555; /* Change the background color on hover */
}












/* client/src/components/VideoBackground.css */
#video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Place the video behind other elements */
}

#background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#content-container {
  position: relative;
  z-index: 2; /* Ensure the content is above the video */
}

/* Additional styling for your content if needed */
#content {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  /* Add other styles as needed */
}

.view-staff-container {
  margin-top: 0%;
  position: relative;
  z-index: 2;
}

.other-message-container {
  background-color: #e5eaec;
}




.bg001 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img001.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;
 

}

.bg002 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img002.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}

.bg003 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img003.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}

.bg004 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img004.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}


.bg005 {

}

.bg006 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img006.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}

.bg007 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img007.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}

.bg008 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img008.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}

.bg010 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img010.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;

}


.bg011 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img011.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;
}

.bg012 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img012.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;
}

.bg013 {
  padding-top: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-image: url(./assets/img013.jpg);
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow:auto;
}




#background-video {
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position:absolute;
  top: 0;
  left: 0;
  /* opacity:90%; */
  border-radius: 20px;
  /* Add a subtle background animation */
  background: linear-gradient(45deg, #3498db, #2c3e50, #3498db, #2c3e50);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

#background-video:hover {
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position:absolute;
  top: 0;
  left: 0;
  opacity:100%;
  border-radius: 20px;
  /* Add a subtle background animation */
  background: linear-gradient(45deg, #3498db, #2c3e50, #3498db, #2c3e50);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}


/* Keyframes for background animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.p-home-div {
  background-color: rgba(189, 195, 199, 0.7);
  border-radius: 20px;
  padding: 20px; /* Increase padding for better spacing */
  margin: 20px; /* Increase margin for better spacing */
  flex: 1;
  max-width: 600px; /* Slightly increased width */
  min-height: 300px; /* Slightly increased height */
  position: relative;
  border: 2px solid #34495e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  /* Add a smooth transition for a subtle effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.p-home-div:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.Topics {
  color: #3498db;
  text-decoration: none;
  /* opacity: 1; */
  z-index: 3;
  /* Add a smooth transition for a subtle effect */
  transition: color 0.3s ease;
  min-width: 320px;
  position: relative;
  /* height:400px */
}


.Topics:hover {
  color: #00000075;
}

.p-home-topic-text {
  color: #97aec6be;
  line-height: 1.6;
  position: relative;
  z-index: 3;
}

.p-home-topic-text :hover{
  color: #ffffff;
  line-height: 1.6;
  position: relative;
}

/* Additional styling for paragraph text */
.p-home-topic-text p {
  margin-bottom: 15px;
  
}

/* Stylish Button Style for Topics */
.Topics {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
 
  color: #ffffff86;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.Topics:hover {
  
}

.p-home-main {
  /* flex: 0.3;
  display: flex;
  flex-direction: column-reverse;
  size: 100%;
  min-height: max-content;
  min-height: max-content; */

  
}


/* AboutUs.css */
.about-us-container {
 
  max-width: 800px;
  opacity: 75%;
  margin:auto;
  margin-top: 100px;
  padding: 40px;
  text-align: center;
  background-color: #f9f9f900;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.about-us-container:hover {
  opacity: 50%;
}

.about-us-container h1 {
  color: #ffffff;
  font-size: 2.5em;
  margin-bottom: 20px;
  
}

.about-us-container p {
  color: #ffffff;
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 15px;
}

.facilities-section h2,
.contact-section h2 {
  color: #ffffff;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.facilities-section ul,
.contact-section p {
  color: #ffffff;
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 15px;
}

.facilities-section ul {
  list-style-type: none;
  padding: 0;
}

.facilities-section li {
  margin-bottom: 10px;
}

.contact-section p {
  margin-bottom: 10px;
}

.contact-section p:last-child {
  margin-bottom: 0;
}


.workout-form-div {
  margin: 100px;
}



/* bmi css */

/* Add this CSS to your stylesheets or in a style tag in your component */

.bmi-img-container {
  max-height:120px;
  max-width: 120px;
  align-content: center;
  margin-left: 150px;
}

.bg10 {
  background-color: #282c35;
  color: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bmi-container {
  background-color: #394046;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 30px;
  width: 400px;
  text-align: center;
  padding-right: 45px;
  opacity: 80%;
 padding-bottom: 75px;
}

.bmi-title h2 {
  color: #61dafb;
}

form {
  margin-top: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #a7c1d6;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #566772;
  border-radius: 6px;
  /* background-color: #2f3841; */
  color:#282c35;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.bmi-btn-sumbit, .bmi-btn-sumbit-outline {
  background-color: #61dafb;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.bmi-btn-sumbit-outline {
  background-color: transparent;
  border: 1px solid #61dafb;
  color: #61dafb;
}

.bmi-btn-sumbit:hover {
  background-color: #4fa9e7;
}

.bmi-btn-sumbit-outline:hover {
  background-color: #61dafb;
  color: #fff;
}

.bmi-below-form {
  margin-top: 20px;
}

.bmi-below-form h3 {
  color: #61dafb;
}

.bmi-below-form p {
  color: #a7c1d6;
}

.bmi-img-container img {
  max-width: 150px;
  max-height: 150px;
  margin-top: 20px;
  border-radius: 8px;
}


/* tictactoe */



.tictactoe-board{
  background-color: rgba(86, 103, 114, 0);
  border-radius: 20px;
  padding-top: 5%;
  padding-left: 20%;
  
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.tictactoe-board h1 {
  text-align: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:#566772;
  height: 50px;
  
}


.tictactoe-inner-board{
  /* background-color: #ffffff68;
  border-radius: 20px;
  padding: 30px;
  margin:30px;
  min-height: fit-content; */

  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 100px 100px 100px;
  cursor: pointer;
  position: relative;
  align-items:normal;
  margin-top: 50px;
  color: #000000;
  
}

.tile {
  /* background-color: #297fb96c;
  margin: 5px;
  border-radius: 5px; */

  color: black;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}



.tictactoe-strike {
  background-color:#2c3e5069;
  margin: 5px;
  border-radius: 5px;
}

.right-border {
 
  border-right: 0.3em solid rgba(0, 153, 255, 0.842);
  line-height: 100%;
  height: 100%;
}

.bottom-border {
  height: 100%;
  line-height: 100%;
  border-bottom: 0.3em solid rgba(0, 153, 255, 0.865);
}



.x-hover:hover::after {
  content: "X";
  opacity: 0.4;
}

.o-hover:hover::after {
  content: "O";
  opacity: 0.4;
}

.strike {
  position: absolute;
  background-color: rgb(190, 42, 42);
  color: #000000;
  
}

.strike-row-1 {
  width: 100%;
  height: 7px;
  top: 15%
}

.strike-row-2 {
  width: 100%;
  height: 7px;
  top: 48%
}

.strike-row-3 {
  width: 100%;
  height: 7px;
  top: 83%
}

.strike-column-1 {
  height: 100%;
  width: 7px;
  left: 15%
}

.strike-column-2 {
  height: 100%;
  width: 7px;
  left: 48%
}

.strike-column-3 {
  height: 100%;
  width: 7px;
  left: 83%
}

.strike-diagonal-1 {
  width: 90%;
  height: 8px;
  top: 50%;
  left:5%;
  transform: skewY(45deg);
}

.strike-diagonal-2 {
  width: 90%;
  height: 8px;
  top: 50%;
  left:5%;
  transform: skewY(-45deg);
}

.tictactoe-game-over {
  text-align: center;
  border: #2980b9 8px;
  border-style: dotted;
  padding: 10px;
  margin-top: 50px;
  font-size: 1.5em;
}

.tictactoe-reset {
  margin-top: 50px;
  padding: 20px;
  background-color: #2980b9;
  color: white;
  width: 100%;
  font-size: 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tictactoe-reset:hover {
  background-color: #1c6da8;
}

.tictactoe-reset:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(41, 128, 185, 1);
}

/* Optional: Add a subtle shadow for a lifted effect */
.tictactoe-reset {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}







/* quize css */

/* Styling for the quiz home page container */
.quize-home {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  padding-left: 0px;
}

/* Styling for the title of the quiz home page */
.quize-home-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000000;
  align-self:normal;
  padding: 55px;
  padding-bottom: 5px;
}

.quize-home-title :hover {
  color: #282c35;
}

.quize-home-rules {
  padding: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Styling for the form container on the quiz home page */
.quize-home-form {
  padding: 50px;
  padding-top: 15px;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}

/* Styling for input fields in the form */
.quize-home-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styling for labels in the form */
.quize-home-form label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Styling for the start section of the quiz home page */
.quize-home-start {
  margin-top: 20px;
  text-align: center;
}

/* Styling for the start button on the quiz home page */
.quize-home-start-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  /* font-weight: bold; */
  text-align: center;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  margin-bottom: 10px;

}

/* Hover effect for the start button */
.quize-home-start-btn:hover {
  background-color: #2980b9;
  text-decoration: none;
}


/* quiz-page */
/* Styling for the overall quiz page container */
.quiz-page {
  width: auto;
  margin: 20px auto;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  min-width: 40%;
  max-width: 550px;
}

/* Styling for the title of the quiz page */
.quiz-page-title {
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  padding: 20px;
}

/* Styling for the grid/container holding the questions */
.quiz-page-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

/* Styling for the next question button */
.quiz-page-next-question {
  /* display: inline-block; */
  /* padding: 10px 20px; */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  background-color: #3498db6f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  /* max-width: 150px; */
  /* align-items:last baseline */
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
/* Hover effect for the next question button */
.quiz-page-next-question:hover {
  background-color: #2980b9;
  text-decoration: none;
}

/* Styling for the previous question button */
.quiz-page-previous-question {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  /* padding: 10px 20px; */
  font-size: 16px;
  font-weight: bold;
  /* text-align: center; */
  background-color: #3333337e;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  /* max-width: 150px; */
  
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* Hover effect for the previous question button */
.quiz-page-previous-question:hover {
  background-color:#333333;
  text-decoration: none;
}


/* quiz questions */


/* Styling for the overall quiz questions container */
.quiz-questions {
  /* max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height:fit-content;
  margin-bottom: 60px; */
  padding: 40px;
}

/* Styling for the question heading */
.quiz-question {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

/* Styling for the radio button and its label */
.quiz-questions input[type='radio'] {
  margin-right: 5px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #3498db;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.quiz-questions input[type='radio']:checked {
  background-color: #3498db;
  border-color: #3498db;
}

.quiz-questions input[type='radio']:checked::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 50%;
  background-color: #fff;
}

.quiz-questions-lable-for-radio-btn {
  font-size: 16px;
  color: #333;
}

/* Styling for the check indicator */
.quiz-questions-check {
  width: 18px;
  height: 18px;
  border: 2px solid #3498db;
  border-radius: 50%;
  margin-left: 5px;
}

/* Styling for the check indicator when selected */
.quiz-questions input[type='radio']:checked ~ .quiz-questions-check {
  background-color: #3498db;
  border-color: #3498db;
}

/* Remove list styles */
.quiz-questions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.quiz-questions li {
  margin-bottom: 10px;
}

/* Your existing CSS */

/* Add the following CSS */


.quiz-questions input[type='radio'] {
  margin-top: 10px;
  margin: 15pxpx;
  display: inline-block;
  height:2px;
  width: 2px;
  
}

.quiz-questions-lable-for-radio-btn {
  display:inline;
  vertical-align: middle;
  align-items: center;
}

/* result */
.quizresultcontainer {
  max-width: 600px;
  margin: 20px auto;
  padding: 40px;
  color: white;
  border: 1.2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height:fit-content;
  opacity: 0.9;
  margin-top: 85px;
}

.quizresultquize-home-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff8f8;
}

.rquizresultesult {
  margin-top: 20px;
}

.quizresultflex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.quizresultbold {
  font-weight: bold;
}

.quizresultstart {
  margin-top: 20px;
}

.quizresultbtn-quiz-restart {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: 2px solid #2980b9;
  color:#2980b9;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.quizresultbtn-quiz-restart:hover {
  background-color: #00000090;
}





/* result table css */
/* Add this CSS to your stylesheet or inline styles */

/* Add this CSS to your stylesheet or inline styles */

.quiz-result-table-div {
  margin: 20px;
  border-radius: 0;
  padding: auto;
  
}

.quiz-result-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quiz-result-table-header {
  /* background-color: #f5f5f5; */
}

.quiz-result-table-header-row {
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

.quiz-result-table-header-row-data,
.quiz-result-table-body-row-data {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.quiz-result-table-body-row:nth-child(even) {
  background-color: #f9f9f9;
}

.quiz-result-table-body-row:hover {
  background-color: #e0e0e0;
}


.navbaremail {
  font-weight: bold;
  padding: 20px;
  font-size: large;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
}


.back {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  padding-right: 0px;
  margin-right: 0px;
  color: #36363686;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.next {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
align-self: last baseline;
align-self: flex-end;
margin-left: 250px;
  padding-right: 0px;
  margin-right: 0px;
  color: #e5e4e486;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

















/* flip fusion css */

.flipfucionhome {
  
  
  font-size: 1.5em;
  text-align: center;
  
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  opacity: 0.9;
  
 
  

}

.flipfusionbutton {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  text-transform: capitalize;
  font-size: 30px;
}

.flipfusionbutton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.flipfusiontitle {
  font-size: 35px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: double;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
 
}

.flipfusioncardgrid {
margin-top: 20px;
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 20px;
}

.flipfusioncard {
  position: relative;
  perspective: 1000px;
  width: 100%;
  max-width: 100px; 
  /* height: 2000px; */
  border-radius: 5px;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  object-fit: cover;

  position: relative;
  height: 150px;
}

.flipfusioncard img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 8px;
  object-fit: cover;

}

/* .flipfusioncard:hover {
  transform: rotateY(180deg);
} */

.flipfusioncardfront,
.flipfusioncardback {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  /* overflow: hidden; */
  /* backface-visibility: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;


}

.flipfusioncardfront image {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 8px;
  object-fit: cover;
}

.flipfusioncardback {

  /* transform: rotateY(180deg); */
}

.flipfusioncard .flipfusioncardfront {
  transform: rotateY(90deg);
  transition: 500ms;
  position: absolute;
}

.flipped .flipfusioncardfront {
  transform: rotateY(0deg);
  transition: 500ms;
}


.flipfusioncard .flipfusioncardback {
  transition: all ease-in 0.3s;
  transition-delay: 0.3s;

  height: 150px;

}

.flipped .flipfusioncardback {
  transform: rotateY(90deg);
  transition-delay: 0s;


}

.flipfusionturn {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  font-family: 'Arial', sans-serif;
}


.question-list {
padding:5px;

}

.quiz-questions-lable-for-radio-btn {
  margin: 10px;
}






.question-list {
  display: flex;
  flex-direction: row; /* Stack children vertically */
}

.quize-input {
  /* Add your styling for the radio input here */
}

.quiz-questions-lable-for-radio-btn {
  /* Add your styling for the label here */
}

.ckeck.checked {
  /* Add your styling for the checked div here */
  margin-bottom: 5px; /* Adjust the margin to your preference */
}


input[type=radio]:checked ~ .ckeck {
  border: 5px solid black
}

input[type=radio]:checked ~ .ckeck::before {
  border: black
}

input[type=radio]:checked ~ .text-primary {
  color:black
}

.checked {
  border: 5px solid black !important;
}

.checked::before {
 background:  black !important;
}

.annnavbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}

.annnavbar h1 {
  color: #f1356d;
}

.annnavbar .links {
  margin-left: auto;
}

.annnavbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.annnavbar a:hover{
  color: #f1356d
}

.appblog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}

.appblog-preview:hover {
  box-shadow: 1px 3px 5pxx rgba(0, 0, 0, 1);
}

.appblog-preview h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px;
}

.annhome {
 max-width: 1000px;
 max-height: 400px;

 margin: 40px auto;
 margin-top: 0px;

 padding-left: 20px;
 overflow: scroll;
 background-color: #282c3500;
 border-radius: 30px;
}

.annblog-list a{
text-decoration: none;
}

.annblog-details h2{
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 10px;
}

.annblog-details div {
  margin: 20px 0;
}

.annblog-details button {
  background: #f1356d;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* AnnCreate.css */

.create-ann {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffe4e4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-ann h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.create-ann form {
  display: flex;
  flex-direction: column;
}

.create-ann label {
  font-size: 16px;
  margin-bottom: 8px;
  color: #555;
}

.create-ann input,
.create-ann textarea,
.create-ann select {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.create-ann button {
  width: 100%;
  padding: 12px;
  background-color: #f1356d;;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-ann button:hover {
  background-color: #f1356d;;
}