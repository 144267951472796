/* Enhanced CSS for bg009 */
.bg011 {
    padding-top: 30px;
    display: flex;                  /* Center content horizontally and vertically */
    flex-direction: column;         /* Stack elements vertically */
    align-items: center;            /* Center horizontally */
    justify-content: center;        /* Center vertically */
    background-image: url(../assets/img011.jpg);
    width: 100vw;
    height: 90vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
}

/* Additional styling for the form elements */
.bg011 form {
    display: flex;
    flex-direction: column;         /* Stack form elements vertically */
    align-items: center;            /* Center form elements horizontally */
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 20px;                  /* Padding inside the form */
    border-radius: 10px;            /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.bg011 input[type="file"] {
    margin-bottom: 15px;            /* Space below the file input */
    padding: 8px;                   /* Padding for better look */
    border: 1px solid #ccc;         /* Border for file input */
    border-radius: 5px;             /* Rounded corners */
}

.bg011 button {
    background-color: #BB2124;      /* Blue background for the button */
    color: white;                   /* White text color */
    border: none;                   /* No border */
    padding: 10px 20px;             /* Padding for the button */
    border-radius: 5px;             /* Rounded corners */
    cursor: pointer;                /* Pointer cursor on hover */
    transition: background-color 0.3s ease, /* Smooth background transition */
                box-shadow 0.3s ease; /* Smooth shadow transition */
}

.bg011 button:hover {
    background-color: #bb212381;      /* Darker blue on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Ensure input elements inside the form are properly styled */
.bg011 form input {
    padding: 10px;                  /* Padding for input fields */
    margin: 10px 0;                 /* Margin above and below input fields */
    width: 100%;                    /* Full width inside the form */
    max-width: 300px;               /* Maximum width for better layout */
    border: 1px solid #ccc;         /* Border for input fields */
    border-radius: 5px;             /* Rounded corners */
}
